import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import { AuthService } from 'src/app/modules/auth/services/auth.service';

@Pipe({
  name: 'timezoneDate',
})
export class TimezoneDatePipe implements PipeTransform {
  constructor(private authService: AuthService) {}

  transform(value: string | Date, format: string = 'YYYY-MM-DD HH:mm'): string {
    const user = this.authService.currentUserValue;
    const timezone = user?.timezone || moment.tz.guess();

    // Handle time-only strings (e.g., "12:00") by appending today's date
    const dateValue =
      typeof value === 'string' && /^\d{2}:\d{2}$/.test(value)
        ? `${moment().format('YYYY-MM-DD')}T${value}`
        : value;

    const utcDate = moment.utc(dateValue);
    const localDate = utcDate.tz(timezone);

    return localDate.isValid() ? localDate.format(format) : 'Invalid date';
  }
}
