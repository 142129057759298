import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { ClipboardModule } from 'ngx-clipboard';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgbModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { ContextMenuModule } from '@perfectmemory/ngx-contextmenu';

import { AuthService } from './modules/auth/services/auth.service';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { environment } from 'src/environments/environment';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

import { FakeAPIService } from './_fake/fake-api.service';
import { AuthInterceptor } from './_core/_interceptors/auth.interceptor';
import { TimezoneDatePipe } from './_metronic/shared/pipes/timezone-date.pipe';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpLoaderFactory } from './pages/translations/http-loader-factory';
import { ToastrModule } from 'ngx-toastr';
import { CommonModule } from '@angular/common';
import { WebSocketService } from './_core/_services/web-socket.service';
import { TimeFormatPipe } from './_metronic/shared/pipes/time-format.pipe';
import { CampaignsService } from './_core/_services/campaigns/campaigns.service';
import { initializeCampaigns } from './_core/_initializers/app.initializer';
import { CampaignsResolver } from './_core/_resolvers/campaigns-resolver.service';
import { CdkContextMenuTrigger, CdkMenu, CdkMenuItem } from '@angular/cdk/menu';

// Socket.IO configuration for ngx-socket-io
const socketConfig: SocketIoConfig = {
  url: environment.socketUri, // Backend WebSocket URL from environment
  options: {
    path: '/api/socket.io', // Path configured in the backend
    transports: ['websocket', 'polling'], // Allow WebSocket and fallback to polling
    withCredentials: true, // Support secure cross-origin requests
  },
};

// Function to initialize the app with user data and WebSocket connection
function appInitializer(authService: AuthService, webSocketService: WebSocketService) {
  return () =>
    new Promise((resolve) => {
      authService.getUserByToken().subscribe({
        next: () => {
          webSocketService.connect(); // Ensure WebSocket connects after token validation
          resolve(true);
        },
        error: () => {
          webSocketService.disconnect(); // Ensure WebSocket disconnects if token is invalid
          resolve(false);
        },
      });
    });
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    CommonModule,
    CdkContextMenuTrigger,
    CdkMenu,
    CdkMenuItem,
    SocketIoModule.forRoot(socketConfig), // Initialize WebSocket with configuration
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgIdleKeepaliveModule.forRoot(),
    HttpClientModule,
    ClipboardModule,
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    NgbModule,
    NgbToastModule,
    SweetAlert2Module.forRoot(),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
  ],
  providers: [
    TimezoneDatePipe,
    TimeFormatPipe,
    WebSocketService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      deps: [AuthService, WebSocketService],
      multi: true,
    },
    // CampaignsService,
    //   {
    //     provide: APP_INITIALIZER,
    //     useFactory: initializeCampaigns,
    //     deps: [CampaignsService],
    //     multi: true,
    //   },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    CampaignsResolver
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
