import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/modules/auth/services/auth.service';

@Component({
  selector: 'app-token-expired-modal',
  templateUrl: './token-expired-modal.component.html',
})
export class TokenExpiredModalComponent implements OnInit, OnDestroy {
  countdown: number = 30;
  private intervalId: any;

  constructor(
    public activeModal: NgbActiveModal, 
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.startCountdown();
  }

  ngOnDestroy() {
    this.clearCountdown();
  }

  private clearCountdown() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  startCountdown() {
    this.clearCountdown();
  
    this.intervalId = setInterval(() => {
      console.log(`Time left: ${this.countdown} seconds`);
      this.countdown--;
  
      if (this.countdown <= 0) {
        console.log('Countdown finished, closing modal with cancel.');
        this.close('cancel');
      }
    }, 1000);
  }
  

  close(reason: string) {
    console.log(`Modal closed with reason: ${reason}`);
    this.clearCountdown();
    this.activeModal.close(reason);
  }
  
}