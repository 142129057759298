import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import {
  Observable,
  BehaviorSubject,
  tap,
  finalize,
  catchError,
  of,
  map,
  throwError,
  retry,
} from "rxjs";
import { environment } from "src/environments/environment";
import { UserUpdateService } from "./user-update.service";
import { AvatarService } from "./avatar.service";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslationService } from "../../i18n/translation.service";
import { TokenExpiredModalComponent } from "src/app/_metronic/layout/components/modals/token-expired-modal/token-expired-modal.component";
import { SessionHeartbeatService } from "src/app/_core/_services/heartbeat/hearbeat.service";
import { CampaignsService } from "src/app/_core/_services/campaigns/campaigns.service";
import { WebSocketService } from "src/app/_core/_services/web-socket.service";
import { jwtDecode } from "jwt-decode";

interface LoginResponse {
  message: string;
  userData: UserType;
}

export interface UserType {
  id: number;
  api_id: number;
  userId: number;
  name: string;
  surname: string;
  email: string;
  role: string;
  avatar: string | null;
  company_id: number;
  timezone: string;
  language: string;
  organization_name?: string;
}

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private isAuthenticatedSubject = new BehaviorSubject<boolean>(
    this.checkInitialAuthState()
  );
  private apiUri = `${environment.apiUri}`;
  private userData: UserType | null = null;
  private userRole = new BehaviorSubject<string>("");
  private isLoadingSubject = new BehaviorSubject<boolean>(false);
  private currentUserSubject = new BehaviorSubject<UserType | null>(null);
  private tokenExpiryTimeout: any;
  private isRefreshing: boolean = false; // Ensure this is reset on logout
  public isLoggedIn = true; // Assume user is logged in initially

  constructor(
    private http: HttpClient,
    private avatarService: AvatarService,
    private webSocketService: WebSocketService,
    private router: Router,
    private modalService: NgbModal,
    private userUpdateService: UserUpdateService,
    private translationService: TranslationService,
    private campaignService: CampaignsService,
    private sessionHeartbeatService: SessionHeartbeatService
  ) {
    this.initializeUserData();
    this.sessionHeartbeatService.sessionExpiring$.subscribe((isExpiring) => {
      if (isExpiring) {
        this.handleTokenExpiry();
      }
    });
  }

  get isLoading$(): Observable<boolean> {
    return this.isLoadingSubject.asObservable();
  }

  get currentUser$(): Observable<UserType | null> {
    return this.currentUserSubject.asObservable();
  }

  get currentUserValue(): UserType | null {
    return this.userData;
  }

  private initializeUserData(): void {
    const storedUserData = sessionStorage.getItem("userData");
    if (storedUserData) {
      const userData = JSON.parse(storedUserData) as UserType;
      this.setUserData(userData); // Apply the user's data including the language

      // Apply the stored language immediately on page load
      if (userData.language) {
        this.translationService.setLanguage(userData.language); // Ensure language is applied
      }
    } else {
      this.clearAuthenticationStatus();
    }
  }

  private clearAuthenticationStatus(): void {
    this.isLoggedIn = false; // Clear flag when user logs out
    this.isAuthenticatedSubject.next(false);
    this.clearUserData();
  }

  private checkInitialAuthState(): boolean {
    return !!sessionStorage.getItem("userData");
  }

  private setUserData(data: UserType): void {
    this.clearPreviousSession(); // Clear any existing session state before setting new user data
    this.userData = data;
    this.userRole.next(data.role);
    this.currentUserSubject.next(data);
    this.isLoggedIn = true;

    // Store the updated user data in session storage, including the language
    sessionStorage.setItem("userData", JSON.stringify(data));

    // Set the user's language based on the updated data
    if (data.language) {
      this.translationService.setLanguage(data.language);
    }

    if (data.avatar) {
      this.avatarService.updateAvatarUrl(data.avatar);
    } else {
      this.avatarService.resetAvatarUrl();
    }

    this.updateAuthenticationStatus();
    this.startTokenExpiryTimer(); // Start the timer whenever user data is set
    this.sessionHeartbeatService.initialize(); // Start the session heartbeat
  }

  updateCurrentUser(data: Partial<UserType>): void {
    if (this.userData) {
      const updatedUserData = { ...this.userData, ...data };
      this.setUserData(updatedUserData);
    }
  }

  register(userData: any): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.http.post(`${this.apiUri}/auth/register`, userData).pipe(
      tap((response: any) => {
        if (response.userData) {
          this.setUserData(response.userData);
          this.updateAuthenticationStatus();
        }
      }),
      catchError((err) => {
        console.error("Registration error:", err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  login(credentials: {
    email: string;
    password: string;
  }): Observable<LoginResponse> {
    this.isLoadingSubject.next(true);

    return this.http
      .post<LoginResponse>(`${this.apiUri}/auth/login`, credentials, {
        withCredentials: true,
      })
      .pipe(
        tap((response: LoginResponse) => {
          if (response.userData) {
            // Set user data and update authentication status
            this.setUserData(response.userData);
            this.updateAuthenticationStatus();

            // Set the user's language immediately after login
            if (response.userData.language) {
              this.translationService.setLanguage(response.userData.language);
            }

            // Reconnect WebSocket after successful login
            // this.sessionHeartbeatService.startHeartbeat(); // Ensure the heartbeat restarts
            this.campaignService.setSelectedCampaign(null); // Reset selected campaign

            console.log("Logging in: Connecting WebSocket...");
            this.webSocketService.connect(); // Ensure WebSocket reconnects
          }
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            console.error("Login failed: Invalid credentials.");
          } else {
            console.error("Login failed:", error);
          }
          return throwError(error);
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }

  forgotPassword(email: string): Observable<boolean> {
    return this.http
      .post<{ message: string }>(`${this.apiUri}/auth/forgot-password`, {
        email,
      })
      .pipe(
        map(() => true),
        catchError((error) => {
          console.error("Forgot password error:", error);
          return of(false);
        })
      );
  }

  getUserByToken(): Observable<boolean> {
    if (!this.userData) {
      return of(false);
    }

    return this.http
      .get<{ message: string }>(`${this.apiUri}/auth/verify-token`, {
        withCredentials: true,
      })
      .pipe(
        map(() => {
          this.updateAuthenticationStatus();
          return true;
        }),
        catchError((error) => {
          if (error.status === 401) {
            console.error(
              "Token verification failed: User is not authenticated."
            );
            this.clearAuthenticationStatus();
          }
          return of(false);
        })
      );
  }

  isTokenExpired(token: string): boolean {
    try {
      const decoded: any = jwtDecode(token);
      const now = Date.now() / 1000;
      return decoded.exp <= now; // Check if the token has expired
    } catch (error) {
      console.error("Error decoding token:", error);
      return true; // Consider the token expired if it cannot be decoded
    }
  }
  

  getApiUserIdFromAuthService(): number | null {
    return this.userData ? this.userData.api_id : null;
  }

  getUserId(id: number): Observable<{ userId: string }> {
    return this.http.get<{ userId: string }>(
      `${this.apiUri}/user/getUserId/${id}`
    );
  }

  private updateAuthenticationStatus(): void {
    const isAuthenticated = !!this.userData;
    this.isAuthenticatedSubject.next(isAuthenticated);
  }

  getIsAuthenticated(): Observable<boolean> {
    return this.isAuthenticatedSubject.asObservable();
  }

  refreshToken(): Observable<string> {
    return this.http
      .post<{ token: string }>(
        `${this.apiUri}/auth/refresh-token`,
        {},
        { withCredentials: true }
      )
      .pipe(
        tap((response) => {
          const newToken = response.token;
  
          if (newToken) {
            this.deleteCookie("token");
            document.cookie = `token=${newToken};path=/;SameSite=Lax;Secure`;
  
            console.log("New token stored, restarting expiry timer.");
            clearTimeout(this.tokenExpiryTimeout); // Clear previous timer
            this.startTokenExpiryTimer(); // Restart the timer
          } else {
            throw new Error("Token not found in response.");
          }
        }),
        map((response) => response.token),
        catchError((error) => {
          console.error("Failed to refresh token:", error);
          this.logout(true); // Logout on failure
          return throwError(() => new Error("Failed to refresh token"));
        })
      );
  }
  
  
  // Utility to delete a cookie
  private deleteCookie(name: string, path: string = ""): void {
    const cookiePath = path ? `; path=${path}` : "";
    document.cookie = `${name}=;${cookiePath};SameSite=Lax;Secure;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  }
  

  changePassword(
    currentPassword: string,
    newPassword: string
  ): Observable<any> {
    return this.http
      .put(
        `${this.apiUri}/auth/change-password`,
        { currentPassword, newPassword },
        { withCredentials: true }
      )
      .pipe(
        tap((response: any) => {
          console.log("Password change response:", response);
        }),
        catchError((error) => {
          console.error("Password change error:", error);
          return throwError(error);
        })
      );
  }

  logout(sessionExpired: boolean = false): void {
    console.log("Logging out user...");
  
    // Stop heartbeat service
    this.sessionHeartbeatService.stopHeartbeat();
  
    // Clear token expiration timer if set
    if (this.tokenExpiryTimeout) {
      clearTimeout(this.tokenExpiryTimeout);
      this.tokenExpiryTimeout = null;
    }
  
    // Clear all cookies with the name "token"
    this.clearAllCookiesByName("token");
  
    // Close any active modals to avoid leftover open state
    if (this.modalService.hasOpenModals()) {
      this.modalService.dismissAll();
    }
  
    // Set the logged-in flag to false
    this.isLoggedIn = false;
  
    // Reset the flag for the modal
    this.isRefreshing = false;
  
    // Clear any additional state that may trigger the modal again
    this.clearUserData();
  
    // Reset selected campaign in CampaignsService
    this.campaignService.setSelectedCampaign(null);
  
    // Make the logout request to the backend
    this.http
      .post(`${this.apiUri}/auth/logout`, {}, { withCredentials: true })
      .pipe(
        retry(3),
        finalize(() => {
          this.clearUserData();
          this.navigateAfterLogout(sessionExpired);
        }),
        catchError((error) => {
          console.error("HTTP request failed during logout:", error);
          return of(null);
        })
      )
      .subscribe();
  }
  
  // Utility method to clear all cookies with the given name
  private clearAllCookiesByName(name: string): void {
    // Get all cookies available to the document
    const cookies = document.cookie.split(";");
  
    // Iterate through cookies and delete those with the matching name
    cookies.forEach((cookie) => {
      const [cookieName] = cookie.trim().split("=");
  
      if (cookieName === name) {
        // Delete cookie with default path and domain
        document.cookie = `${cookieName}=; path=/; SameSite=Lax; Secure; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  
        // Optionally handle other paths or domains if applicable
        document.cookie = `${cookieName}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
      }
    });
  
    console.log(`Cleared all cookies with name "${name}".`);
  }
  

  private navigateAfterLogout(sessionExpired: boolean): void {
    const queryParams = sessionExpired ? { sessionExpired: "true" } : {};
    this.router.navigate(["/auth/login"], { queryParams });
  }

  hasRole(roles: string[]): boolean {
    return roles.includes(this.userData?.role || "");
  }

  private clearUserData(): void {
    sessionStorage.removeItem("userData"); // Clear user data
    sessionStorage.removeItem("avatarUrl"); // Clear avatar URL
    localStorage.clear(); // Clear localStorage (if used for storing anything)
    sessionStorage.clear(); // Clear sessionStorage completely
    this.userData = null; // Reset local user data
    this.userRole.next("");
    this.currentUserSubject.next(null);
    this.avatarService.resetAvatarUrl();
    this.isAuthenticatedSubject.next(false);
    if (this.tokenExpiryTimeout) {
      clearTimeout(this.tokenExpiryTimeout);
    }
  }

  private clearPreviousSession(): void {
    // Method to clear previous session state to avoid conflicts when setting new user data
    if (this.tokenExpiryTimeout) {
      clearTimeout(this.tokenExpiryTimeout);
      this.tokenExpiryTimeout = null;
    }
    this.sessionHeartbeatService.stopHeartbeat();
    if (this.modalService.hasOpenModals()) {
      this.modalService.dismissAll();
    }
    this.isRefreshing = false;
  }

  startTokenExpiryTimer(): void {
    const token = this.getTokenFromCookie();
    if (!token) {
      console.log("No token found in cookies.");
      return;
    }
  
    const expirationDate = this.getTokenExpirationDate(token);
    if (!expirationDate) {
      console.log("Could not determine expiration date from token.");
      return;
    }
  
    const timeoutDuration = expirationDate.getTime() - Date.now();
    const warningTime = 30000; // 10 seconds before expiration
  
    console.log(
      `Token expires in ${Math.floor(timeoutDuration / 1000)} seconds.`
    );
  
    // Clear any existing timers to prevent duplicate behavior
    if (this.tokenExpiryTimeout) {
      clearTimeout(this.tokenExpiryTimeout);
    }
  
    // Show warning modal before expiration
    if (timeoutDuration > warningTime) {
      this.tokenExpiryTimeout = setTimeout(() => {
        console.log("Token nearing expiration. Showing warning modal.");
        this.showTokenExpirationWarning();
      }, timeoutDuration - warningTime);
    } else {
      console.log("Token already close to expiration. Showing warning modal immediately.");
      this.showTokenExpirationWarning();
    }
  
    console.log("Token expiry timer set successfully.");
  
    // Logout after token expiration (fail-safe mechanism)
    setTimeout(() => {
      const currentToken = this.getTokenFromCookie();
      if (!currentToken || this.isTokenExpired(currentToken)) {
        console.log("Token expired or invalid. Logging out.");
        this.logout(true);
      } else {
        console.log("Token still valid. Skipping logout.");
      }
    }, timeoutDuration);
  }
  

  private showTokenExpirationWarning(): void {
    console.log("Attempting to show token expiry modal");

    if (this.modalService.hasOpenModals()) {
      console.log("A modal is already open, skipping duplicate modal.");
      return;
    }

    const modalRef = this.modalService.open(TokenExpiredModalComponent, {
      backdrop: "static",
      keyboard: false,
      centered: true,
      size: "md",
    });

    modalRef.result
      .then((result) => {
        console.log("Modal result:", result);

        if (result === "extend") {
          this.refreshToken().subscribe(
            () => {
              console.log(
                "Token successfully refreshed, restarting expiry timer."
              );
              this.startTokenExpiryTimer();
            },
            (error) => {
              console.error("Token refresh failed:", error);
              this.logout(true); // Logout if refresh fails
            }
          );
        } else {
          console.log("User chose to logout.");
          this.logout(true);
        }
      })
      .catch((reason) => {
        console.log("Modal dismissed without action:", reason);
        this.logout(true); // Logout if modal dismissed without action
      });
  }

  getTokenFromCookie(): string | null {
    const cookieName = "token";
    const matches = document.cookie.match(
      new RegExp(`(?:^|;\\s*)${cookieName}=([^;]*)`)
    );
    return matches ? decodeURIComponent(matches[1]) : null;
  }

  private getTokenExpirationDate(token: string): Date | null {
    try {
      const tokenParts = token.split(".");
      if (tokenParts.length !== 3) {
        console.error("Token does not have the expected JWT structure.");
        return null;
      }

      const decodedToken = JSON.parse(atob(tokenParts[1]));
      if (decodedToken.exp === undefined) {
        console.log(
          "Token does not have an expiration date (exp field is missing)."
        );
        return null;
      }

      const date = new Date(0);
      date.setUTCSeconds(decodedToken.exp);
      return date;
    } catch (error) {
      console.error("Error decoding token:", error);
      return null;
    }
  }

  handleTokenExpiry(): void {
    if (!this.isLoggedIn) {
      console.log("User is logged out, skipping token expiry modal.");
      return;
    }

    console.log("Attempting to show token expiry modal");

    if (this.modalService.hasOpenModals()) {
      console.log("Modal already open, skipping duplicate modal.");
      return;
    }

    // Set the modalVisible flag to true
    this.sessionHeartbeatService.modalVisible = true;

    const modalRef = this.modalService.open(TokenExpiredModalComponent, {
      backdrop: "static",
      keyboard: false,
      centered: true,
      size: "md",
    });

    modalRef.result
      .then((result) => {
        console.log("Modal result:", result);

        if (result === "extend") {
          this.refreshToken().subscribe(
            () => {
              console.log(
                "Token refreshed successfully, restarting expiry timer."
              );
              this.startTokenExpiryTimer();
            },
            (error) => {
              console.error("Failed to refresh token:", error);
              this.logout(true);
            }
          );
        } else {
          console.log("User chose to logout.");
          this.logout(true);
        }
      })
      .catch((reason) => {
        console.log("Modal dismissed:", reason);
        this.logout(true);
      })
      .finally(() => {
        // Set the modalVisible flag back to false when the modal is closed
        this.sessionHeartbeatService.modalVisible = false;
      });
  }
}
