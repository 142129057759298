import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TokenExpiredModalComponent } from 'src/app/_metronic/layout/components/modals/token-expired-modal/token-expired-modal.component';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { jwtDecode } from 'jwt-decode';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private modalRef: NgbModalRef | null = null;
  private isRefreshing = false;
  private refreshCooldown = 30000;
  private lastRefresh = 0;

  constructor(
    private modalService: NgbModal,
    private injector: Injector
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Skip authentication for certain endpoints
    if (req.url.endsWith('/auth/login') || 
        req.url.endsWith('/auth/register') || 
        req.url.endsWith('/auth/refresh-token') ||
        req.url.endsWith('/auth/verify-token')) {
      return next.handle(req);
    }
  
    // Token does not need to be manually added if it's in an HttpOnly cookie.
    // Simply pass the request to the next handler.
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          return this.handleAuthError(req, next);
        }
        return throwError(() => error);
      })
    );
  }
  

  getTokenFromCookie(): string | null {
    const cookieName = 'token';
    const cookies = document.cookie.split(';');
    for (let cookie of cookies) {
        const [key, value] = cookie.trim().split('=');
        if (key === cookieName) {
            return decodeURIComponent(value);
        }
    }
    return null; // Return null if the cookie is not found
}

  
  

  private isTokenExpired(token: string): boolean {
    try {
      const decoded: any = jwtDecode(token);
      const now = Date.now() / 1000;
      return decoded.exp < now || decoded.exp - now < 60;
    } catch (error) {
      console.error('Error decoding token:', error);
      return true;
    }
  }

  private handleAuthError(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authService = this.injector.get(AuthService);
    this.isRefreshing = true;
  
    // Close any existing modals to prevent multiple instances
    const modalService = this.injector.get(NgbModal);
    if (modalService.hasOpenModals()) {
      modalService.dismissAll();
    }
  
    // Open the modal
    this.modalRef = modalService.open(TokenExpiredModalComponent, {
      backdrop: 'static',
      centered: true,
      keyboard: false,
      size: 'lg',
    });
  
    return new Observable((observer) => {
      const timeoutId = setTimeout(() => {
        // Timeout logic in case the modal stays open too long
        if (this.modalRef) {
          this.modalRef.close();
        }
        authService.logout(true);
        observer.error('Session expired - logged out due to timeout.');
      }, 30000);
  
      this.modalRef?.result
        .then(
          (result) => {
            clearTimeout(timeoutId);
            this.isRefreshing = false;
  
            if (result === 'extend') {
              console.log('User chose to extend session');
              authService.refreshToken().subscribe({
                next: (newToken) => {
                  console.log('Token refreshed successfully:', newToken);
  
                  // Clone the failed request with the new token
                  const clonedReq = req.clone({
                    setHeaders: {
                      Authorization: `Bearer ${newToken}`,
                    },
                  });
  
                  // Retry the original request
                  next.handle(clonedReq).subscribe(observer);
                },
                error: (refreshError) => {
                  console.error('Failed to refresh token:', refreshError);
                  authService.logout(true);
                  observer.error('Failed to refresh token.');
                },
              });
            } else {
              console.log('User chose to logout');
              authService.logout(true);
              observer.error('User chose to logout.');
            }
          },
          (dismissReason) => {
            clearTimeout(timeoutId);
            console.log('Modal dismissed:', dismissReason);
            authService.logout(true);
            observer.error('Session expired - logged out.');
          }
        )
        .catch((modalError) => {
          clearTimeout(timeoutId);
          console.error('Modal error:', modalError);
          authService.logout(true);
          observer.error('Modal operation failed.');
        });
    });
  }
  
}