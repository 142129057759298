import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { interval, Subscription, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SessionHeartbeatService {
  private heartbeatInterval = 540000;
  private heartbeatSubscription: Subscription | null = null;
  public modalVisible = false;

  // Event to notify when the session is expiring
  public sessionExpiring$ = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {}

  startHeartbeat(): void {
    console.log('Attempting to start heartbeat...');

    this.stopHeartbeat();

    this.heartbeatSubscription = interval(this.heartbeatInterval).subscribe(() => {
      if (!this.modalVisible) {
        this.checkSessionOnBackend();
      } else {
        console.log('Skipping session check as the modal is visible.');
      }
    });

    console.log('Heartbeat started.');
  }

  stopHeartbeat(): void {
    if (this.heartbeatSubscription) {
      this.heartbeatSubscription.unsubscribe();
      this.heartbeatSubscription = null;
      console.log('Heartbeat stopped.');
    }
  }

  private checkSessionOnBackend(): void {
    console.log('Checking session on backend...');
    this.http.post<{ message: string }>(`${environment.apiUri}/auth/check-session`, {}, { withCredentials: true }).subscribe({
      next: (response) => {
        console.log('Session check response:', response);

        if (response.message === 'Token expiring soon') {
          console.log('Token is expiring soon. Notifying listeners.');
          this.sessionExpiring$.next(true);
        } else if (response.message === 'Session is valid.') {
          console.log('Session is valid.');
        }
      },
      error: (err) => {
        console.error('Error while checking session:', err);
        if (err.status === 401) {
          console.log('Session expired or invalid. Notifying listeners.');
          this.sessionExpiring$.next(true);
        }
      },
    });
  }

  initialize(): void {
    console.log('Initializing SessionHeartbeatService...');
    this.startHeartbeat();
  }
}
